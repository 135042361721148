import { useTranslation } from 'react-i18next';

import dondeVamos1 from './images/hacia_donde_vamos/1_Escuelas_derecho.jpg';
import dondeVamos2 from './images/hacia_donde_vamos/2_licenciatura_Derecho.jpg';
import dondeVamos3 from './images/hacia_donde_vamos/3_Personal_Docente.jpg';
import dondeVamos4 from './images/hacia_donde_vamos/4_Regulacion_educacion.jpg';
import dondeVamos5 from './images/hacia_donde_vamos/5_Regulacion_profesion.jpg';
import dondeVamos6 from './images/hacia_donde_vamos/6_educacion_continua.jpg';
import dondeVamos7 from './images/hacia_donde_vamos/7_Sinergias.jpg';
import dondeVamos8 from './images/hacia_donde_vamos/8_Profesiones_sociedad.jpg';
import equipoCEEAD from './equipo_ceead/images/equipo_ceead.jpg';
// import bolsaTrabajo from './images/bolsa_trabajo/Bolsa_trabajo_.jpg';
import especializacion from './images/fortalezas/especializacion.png';
import investigacion from './images/fortalezas/investigacion.png';
import innovacion from './images/fortalezas/innovacion.png';
import enfoque from './images/fortalezas/enfoque.png';
import impacto from './images/fortalezas/impacto.png';
import colaboracion from './images/fortalezas/colaboracion.png';
import especializacionBw from './images/fortalezas/especializacion_bw.png';
import investigacionBw from './images/fortalezas/investigacion_bw.png';
import innovacionBbw from './images/fortalezas/innovacion_bw.png';
import enfoqueBw from './images/fortalezas/enfoque_bw.png';
import impactoBw from './images/fortalezas/impacto_bw.png';
import colaboracionBw from './images/fortalezas/colaboracion_bw.png';
import informes from '../../../data/informes.json';
import { Informe } from './Informe';
import { SectionImage } from '../../ui/sections/SectionImage';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

export const QuienesSomos = () => {
  const { t } = useTranslation('quienesSomos');
  useTitle(t('introduccion.title'));
  useGoogleAnalytics();
  return (
    <>
      <section id="content">
        <div className="content-wrap py-0">
          <div className="container clearfix my-5">
            <div className="row">
              <div className="col-12">
                <h2 className="dots text-red">{t('introduccion.title')}</h2>
                <p>{t('introduccion.txt1')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>{t('introduccion.misionTitle')}</h3>
                <p>{t('introduccion.misionTxt')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>{t('introduccion.visionTitle')}</h3>
                <p>{t('introduccion.visionTxt')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>{t('introduccion.valoresTitle')}</h3>
                <ul className="ml-5">
                  <li>{t('introduccion.valor1')}</li>
                  <li>{t('introduccion.valor2')}</li>
                  <li>{t('introduccion.valor3')}</li>
                  <li>{t('introduccion.valor4')}</li>
                  <li>{t('introduccion.valor5')}</li>
                  <li>{t('introduccion.valor6')}</li>
                  <li>{t('introduccion.valor7')}</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3> {t('introduccion.fortalezasTitle')}</h3>
                <p>{t('introduccion.fortalezasTxt')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="p-3">
                  <div className="center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src={especializacionBw}
                      onMouseOver={(e) => (e.currentTarget.src = especializacion)}
                      onMouseOut={(e) => (e.currentTarget.src = especializacionBw)}
                      alt={t('introduccion.especializacionSubtitle')}
                    />
                  </div>
                  <div className="hover">
                    <p className="text-center">
                      <b>{t('introduccion.especializacionSubtitle')}</b>
                      <br />
                      {t('introduccion.especializacionTxt')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="p-3">
                  <div className="center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src={investigacionBw}
                      onMouseOver={(e) => (e.currentTarget.src = investigacion)}
                      onMouseOut={(e) => (e.currentTarget.src = investigacionBw)}
                      alt={t('introduccion.investigacionSubtitle')}
                    />
                  </div>
                  <div className="hover">
                    <p className="text-center">
                      <b>{t('introduccion.investigacionSubtitle')}</b>
                      <br />
                      {t('introduccion.investigacionTxt')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="p-3">
                  <div className="center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src={innovacionBbw}
                      onMouseOver={(e) => (e.currentTarget.src = innovacion)}
                      onMouseOut={(e) => (e.currentTarget.src = innovacionBbw)}
                      alt={t('introduccion.inovacionSubtitle')}
                    />
                  </div>
                  <div>
                    <p className="text-center">
                      <b>{t('introduccion.inovacionSubtitle')}</b>
                      <br />
                      {t('introduccion.inovacionTxt')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="p-3">
                  <div className="center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src={enfoqueBw}
                      onMouseOver={(e) => (e.currentTarget.src = enfoque)}
                      onMouseOut={(e) => (e.currentTarget.src = enfoqueBw)}
                      alt={t('introduccion.enfoqueSubtitle')}
                    />
                  </div>
                  <div>
                    <p className="text-center">
                      <b>{t('introduccion.enfoqueSubtitle')}</b>
                      <br />
                      {t('introduccion.enfoqueTxt')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="p-3">
                  <div className="center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src={impactoBw}
                      onMouseOver={(e) => (e.currentTarget.src = impacto)}
                      onMouseOut={(e) => (e.currentTarget.src = impactoBw)}
                      alt={t('introduccion.impactoSubtitle')}
                    />
                  </div>
                  <div>
                    <p className="text-center">
                      <b>{t('introduccion.impactoSubtitle')}</b>
                      <br />
                      {t('introduccion.impactoTxt')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="p-3">
                  <div className="center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src={colaboracionBw}
                      onMouseOver={(e) => (e.currentTarget.src = colaboracion)}
                      onMouseOut={(e) => (e.currentTarget.src = colaboracionBw)}
                      alt={t('introduccion.colaboracionSubtitle')}
                    />
                  </div>
                  <div>
                    <p className="text-center">
                      <b>{t('introduccion.colaboracionSubtitle')}</b>
                      <br />
                      {t('introduccion.colaboracionTxt')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading-block text-center pt-4">
              <h2>{t('haciaDondeVamos.title')}</h2>
            </div>
            <div className="row col-12 pb-5">
              <p>{t('haciaDondeVamos.introTxt')}</p>
            </div>
            <div className="row pb-5">
              <div className="col-4">
                <img src={dondeVamos1} alt={t('haciaDondeVamos.escuelasSubtitle')} className="rounded shadow" />
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-2  align-items-center">
                    <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>1</p>
                  </div>
                  <div className="col-10  align-items-center">
                    <p style={{ color: '#333333' }}>
                      <b>{t('haciaDondeVamos.escuelasSubtitle')}</b>
                    </p>
                    <p style={{ color: '#707070' }}>
                      <b>{t('haciaDondeVamos.escuelasTxt1')}</b>
                    </p>
                    <p style={{ color: '#707070' }}>
                      <b>{t('haciaDondeVamos.escuelasTxt2')}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-8">
                <div className="row">
                  <div className="col-2  align-items-center">
                    <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>2</p>
                  </div>
                  <div className="col-10  align-items-center">
                    <p style={{ color: '#333333' }}>
                      <b>{t('haciaDondeVamos.licenciaturaSubtitle')}</b>
                    </p>
                    <p style={{ color: '#707070' }}>
                      <b>{t('haciaDondeVamos.licenciaturaTxt')}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img src={dondeVamos2} alt={t('haciaDondeVamos.licenciaturaSubtitle')} className="rounded shadow" />
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-4">
                <img src={dondeVamos3} alt={t('haciaDondeVamos.personalSubtitle')} className="rounded shadow" />
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-2  align-items-center">
                    <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>3</p>
                  </div>
                  <div className="col-10  align-items-center">
                    <p style={{ color: '#333333' }}>
                      <b>{t('haciaDondeVamos.personalSubtitle')}</b>
                    </p>
                    <p style={{ color: '#707070' }}>
                      <b>{t('haciaDondeVamos.personalTxt1')}</b>
                    </p>
                    <p style={{ color: '#707070' }}>
                      <b>{t('haciaDondeVamos.personalTxt2')}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-5">
              <button className="btn btn-secondary mx-auto" type="button" data-toggle="collapse" data-target="#verMas1">
                {t('haciaDondeVamos.verMasBtn')} <i className="icon-arrow-alt-circle-down1" />
              </button>
            </div>
            <div className="collapse pb-5" id="verMas1">
              <div className="row pb-5">
                <div className="col-8">
                  <div className="row">
                    <div className="col-2  align-items-center">
                      <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>4</p>
                    </div>
                    <div className="col-10  align-items-center">
                      <p style={{ color: '#333333' }}>
                        <b>{t('haciaDondeVamos.edJuridicaSubtitle')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.edJuridicaTxt1')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.edJuridicaTxt2')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.edJuridicaTxt3')}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <img src={dondeVamos4} alt={t('haciaDondeVamos.edJuridicaSubtitle')} className="rounded shadow" />
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-4">
                  <img src={dondeVamos5} alt={t('haciaDondeVamos.profJuridicaSubtitle')} className="rounded shadow" />
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="col-2  align-items-center">
                      <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>5</p>
                    </div>
                    <div className="col-10  align-items-center">
                      <p style={{ color: '#333333' }}>
                        <b>{t('haciaDondeVamos.profJuridicaSubtitle')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.profJuridicaTxt1')}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-8">
                  <div className="row">
                    <div className="col-2  align-items-center">
                      <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>6</p>
                    </div>
                    <div className="col-10  align-items-center">
                      <p style={{ color: '#333333' }}>
                        <b>{t('haciaDondeVamos.edContinuaSubtitle')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.edContinuaTxt1')}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <img src={dondeVamos6} alt={t('haciaDondeVamos.edContinuaSubtitle')} className="rounded shadow" />
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-4">
                  <img src={dondeVamos7} alt={t('haciaDondeVamos.sinergiasSubtitle')} className="rounded shadow" />
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="col-2  align-items-center">
                      <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>7</p>
                    </div>
                    <div className="col-10  align-items-center">
                      <p style={{ color: '#333333' }}>
                        <b>{t('haciaDondeVamos.sinergiasSubtitle')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.sinergiasTxt1')}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-8">
                  <div className="row">
                    <div className="col-2  align-items-center">
                      <p style={{ color: '#C80021', fontSize: '100px', fontWeight: 'bold' }}>8</p>
                    </div>
                    <div className="col-10  align-items-center">
                      <p style={{ color: '#333333' }}>
                        <b>{t('haciaDondeVamos.juridicasSociedadSubtitle')}</b>
                      </p>
                      <p style={{ color: '#707070' }}>
                        <b>{t('haciaDondeVamos.juridicasSociedadTxt1')}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <img
                    src={dondeVamos8}
                    alt={t('haciaDondeVamos.juridicasSociedadSubtitle')}
                    className="rounded shadow"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h2 className="dots text-red">{t('informes.title')}</h2>
                <p>
                  <b>{t('informes.informesTxt1')}</b>
                </p>
              </div>
            </div>
            <div className="row">
              {informes.map((informe) => (
                <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={informe.id}>
                  <Informe informe={informe} />
                </div>
              ))}
            </div>
            <div className="row">
              <p>{t('informes.informesTxt2')}</p>
              <p>{t('informes.informesTxt3')}</p>
            </div>
          </div>
        </div>
      </section>
      <SectionImage backgroundImage={equipoCEEAD} margin={0}>
        <div className="emphasis-title text-left pb-6">
          <h2 className="dots dots-white font-weight-bold">{t('equipo.title')}</h2>
        </div>
        <div className="emphasis-title text-center pb-6">
          <h3>{t('equipo.equipoTxt1')}</h3>
        </div>
        <div className="emphasis-title text-right">
          <Link to="/quienes-somos/equipo-ceead">
            <h3>
              {t('equipo.equipoTxt2')} <i className="icon-arrow-alt-circle-right1" />
            </h3>
          </Link>
        </div>
      </SectionImage>
      {/* <SectionImage backgroundImage={bolsaTrabajo} margin={0}>
        <div className="emphasis-title text-left pb-6">
          <h2 className="dots dots-white font-weight-bold">{t('bolsaTrabajo.title')}</h2>
        </div>
        <div className="emphasis-title text-center pb-6" />
        <div className="emphasis-title text-right">
          <Link to="/quienes-somos/bolsa-trabajo">
            <h3>
              {t('bolsaTrabajo.bolsaTxt1')} <i className="icon-arrow-alt-circle-right1" />
            </h3>
          </Link>
        </div>
      </SectionImage>
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="row pb-5">
              <div id="alianzas" className="col-12">
                <h2 className="dots text-red">{t('sinergias.alianzasTitle')}</h2>
                <p>{t('sinergias.alianzasTxt')}</p>
                <p className="text-center">
                  <Link to="/quienes-somos/alianzas" className="btn btn-lg btn-secondary">
                    {t('sinergias.verMasBtn')} <i className="icon-arrow-alt-circle-right1" />
                  </Link>
                </p>
              </div>
            </div> 
            <div className="row">
              <div className="col-12">
                <h3>{t('sinergias.consejoDirTitle')}</h3>
                <ul>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreDirectivo1')}</b>
                    <br />
                    {t('sinergias.descripcionDirectivo1')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreDirectivo2')}</b>
                    <br />
                    {t('sinergias.descripcionDirectivo2_1')}
                    <br />
                    {t('sinergias.descripcionDirectivo2_2')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreDirectivo3')}</b>
                    <br />
                    {t('sinergias.descripcionDirectivo3_1')}
                    <br />
                    {t('sinergias.descripcionDirectivo3_2')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreDirectivo4')}</b>
                    <br />
                    {t('sinergias.descripcionDirectivo4_1')}
                    <br />
                    {t('sinergias.descripcionDirectivo4_2')}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>{t('sinergias.consejoConTitle')}</h3>
                <ul>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo1')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo1_1')}
                    <br />
                    {t('sinergias.descripcionConsultivo1_2')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo2')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo2_1')}
                    <br />
                    {t('sinergias.descripcionConsultivo2_2')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo3')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo3')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo4')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo4_1')}
                    <br />
                    {t('sinergias.descripcionConsultivo4_2')}
                    <br />
                    {t('sinergias.descripcionConsultivo4_3')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo5')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo5')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo6')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo6_1')}
                    <br />
                    {t('sinergias.descripcionConsultivo6_2')}
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <b>{t('sinergias.nombreConsultivo7')}</b>
                    <br />
                    {t('sinergias.descripcionConsultivo7_1')}
                    <br />
                    {t('sinergias.descripcionConsultivo7_2')}
                  </li>
                </ul>
              </div> 
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};
